<template>
  <div class="absolute top-10 left-1/2 -translate-x-1/2 flex flex-col gap-4 items-center z-50">
    <slot></slot>
  </div>
</template>

<script>

export default {

}
</script>

<style scoped>

</style>