export default [
    {
        'title': '-',
    },
    {
        'route': '/',
        'title': 'Главная',
    },
    // {
    //     'title': 'Блог бренда',
    // },
    {
        'title': 'Оплата',
        'link': '/assets/files/payment.pdf',
    },
    {
        'title': 'Доставка',
        'link': '/assets/files/delivery.pdf',
    },
    {
        'title': 'Оферта',
        'link': '/assets/files/offer.pdf',
    },
    // {
    //     'title': '-',
    // },
    // {
    //     'title': 'Написать в What App',
    // },
    // {
    //     'title': 'Написать в Telegram',
    // },
]