export default [
    // {
    //     'id': 1,
    //     'title': 'Магазин',
    //     'content': [
    //         {
    //             id: 1,
    //             name: 'Главная',
    //             link: '/'
    //         },
    //         {
    //             id: 2,
    //             name: 'Каталог',
    //             link: '/'
    //         },
    //     ]
    // },
    {
        'id': 2,
        'title': 'Покупателям',
        'content': [
            {
                id: 1,
                name: 'Оплата',
                link: '/assets/files/payment.pdf'
            },
            {
                id: 2,
                name: 'Доставка',
                link: '/assets/files/delivery.pdf'
            },
        ]
    },
    // {
    //     'id': 3,
    //     'title': 'Соц. сети',
    //     'content': [
    //         {
    //             id: 1,
    //             name: 'Tik tok',
    //             link: '#'
    //         },
    //         {
    //             id: 2,
    //             name: 'Pinterest',
    //             link: '#'
    //         },
    //     ]
    // },
    {
        'id': 4,
        'title': 'Контакты',
        'content': [
            {
                id: 1,
                name: '+7 (912)821-35-99',
                link: 'tel:+79128213599'
            },
            {
                id: 2,
                name: 'store@oxygen-an.ru',
                link: 'mailto:store@oxygen-an.ru'
            },
        ]
    },
    {
        'id': 5,
        'title': 'Документы',
        'content': [
            {
                id: 1,
                name: 'Политика',
                link: '/assets/files/privacy-policy.pdf'
            },
            {
                id: 2,
                name: 'Оферта',
                link: '/assets/files/offer.pdf'
            },
        ]
    },
]