<template>
  <button class="bg-white w-9 h-9 flex justify-center items-center rounded-full">
    <ChevronLeftIcon class="h-5 w-5 text-dark stroke-1 cursor-pointer"/>
  </button>
</template>

<script>
import {ChevronLeftIcon} from "@heroicons/vue/24/solid";

export default {
  components: {ChevronLeftIcon}

}
</script>

<style scoped>

</style>