<template>
  <div class="h-full">
    <div class="container flex flex-col items-center justify-center gap-7 py-48">
      <h1 class="text-2xl font-bold">Ошибка 404</h1>
      <p class="text-lg font-medium">Страница не найдена :(</p>
    </div>
  </div>
</template>

<script>
</script>

<style scoped>

</style>