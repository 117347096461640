<template>
  <svg :width="newWidth ? newWidth : width" :height="newHeight ? newHeight : height" :viewBox="viewBox" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path :d="newWidth || newHeight ? scaledCirclePath : originalTgIconPath" fill="white"/>
    <path :d="newWidth || newHeight ? scaledIconPath : originalIconPath" fill="#039BE5"/>
  </svg>
</template>

<script>
export default {
  data() {
    return {
      width: 40,
      height: 40,
      originalTgIconPath: "M6.78956 9.0389H33.2396V31.7262H6.78956V9.0389Z",
      originalIconPath: "M18.098 0.0501758C13.0835 0.566843 8.62451 2.81356 5.1981 6.53983C0.363578 11.7926 -1.25575 19.4095 1.00505 26.2671C2.02984 29.375 3.64917 31.974 6.0195 34.3068C9.16428 37.399 12.9818 39.2543 17.5504 39.904C18.4813 40.0371 21.5557 40.0293 22.5179 39.8962C27.1021 39.2621 31.0526 37.305 34.2287 34.0876C37.3891 30.8858 39.2431 27.0891 39.9003 22.4704C40.0332 21.5467 40.0332 18.4623 39.9003 17.5386C39.5248 14.9005 38.7581 12.5206 37.5925 10.4148C36.6772 8.74741 35.6133 7.32266 34.2287 5.9214C31.1152 2.76659 27.2429 0.80952 22.7917 0.151944C21.8295 0.0110343 19.0524 -0.051592 18.098 0.0501758ZM29.3629 12.1292C29.7071 12.278 29.8714 12.6068 29.8714 13.1547C29.8714 13.6558 26.5623 29.375 26.3902 29.7194C26.1399 30.2048 25.6001 30.3848 25.0525 30.1813C24.8647 30.1108 23.0577 28.8035 20.4135 26.8386L19.7564 26.3532L18.3405 27.7075C17.3 28.6939 16.8463 29.0853 16.6586 29.1245C16.5178 29.1558 16.3848 29.1636 16.3691 29.1401C16.3457 29.1166 16.4004 27.9815 16.4943 26.6037L16.6586 24.1144L17.214 23.6055C17.5113 23.3237 19.6313 21.4058 21.9155 19.3391C24.2076 17.2646 26.0773 15.5267 26.0773 15.4641C26.0773 15.378 25.9912 15.3467 25.8035 15.3545C25.5766 15.3623 24.4188 16.059 19.7251 19.0103C16.5412 21.0222 13.8893 22.6661 13.8423 22.6661C13.6311 22.6661 8.69491 21.077 8.46023 20.9361C7.95956 20.6229 8.06908 20.1611 8.71838 19.7931C8.89048 19.6992 10.1343 19.1904 11.4877 18.6737C12.841 18.1492 15.3991 17.1628 17.1592 16.4818C18.9272 15.8007 22.072 14.5873 24.1607 13.781C28.737 12.0118 28.6901 12.0275 28.917 12.0196C29.0108 12.0196 29.2142 12.0666 29.3629 12.1292Z",
    }
  },
  computed: {
    scaledCirclePath() {
      // Вычисление коэффициентов масштабирования
      const widthScale = this.newWidth / this.width;
      const heightScale = this.newHeight / this.height;
      return this.originalTgIconPath.replace(/(\d+(\.\d+)?)/g, match => parseFloat(match) * (widthScale > heightScale ? heightScale : widthScale));
    },
    scaledIconPath() {
      // Вычисление коэффициентов масштабирования
      const widthScale = this.newWidth / this.width;
      const heightScale = this.newHeight / this.height;
      return this.originalIconPath.replace(/(\d+(\.\d+)?)/g, match => parseFloat(match) * (widthScale > heightScale ? heightScale : widthScale));
    },
    viewBox() {
      const resultWidth = this.newWidth ? this.newWidth : this.width
      const resultHeight = this.newHeight ? this.newHeight : this.height
      return `0 0 ${resultWidth} ${resultHeight}`
    }
  },
  props: {
    newWidth: {
      type: Number,
      required: false,
    },
    newHeight: {
      type: Number,
      required: false,
    }
  }
}
</script>

<style scoped>

</style>